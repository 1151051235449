import React, { Component } from "react"
import { Div } from "atomize"
import ImageLazyLoad from "./ImageLazyLoad"
import VideoOnScroll from "./VideoOnScroll"

export default class VideoOrImage extends Component {
  isVideo = file => {
    const acceptedVideoTypes = ["mp4", "mov"]
    const fileExtensition = file.split(".").pop().split(/\#|\?/)[0]

    return file && acceptedVideoTypes.includes(fileExtensition)
  }

  render() {
    const { file, product, id, hideTracker, ...rest } = this.props
    const isVideo = file && this.isVideo(file)

    if (isVideo) {
      return (
        <VideoOnScroll
          file={file}
          id={id}
          pos="relative"
          overflow="hidden"
          hideTracker={hideTracker}
          {...rest}
        />
      )
    }

    return (
      <Div
        p="1.25rem"
        bg="gray"
        borderColor="yellowPink"
        border={{ t: "1px solid" }}
        className="lazy-img-container"
      >
        <ImageLazyLoad
          bgImg={file}
          bgPos="center"
          bgSize="cover"
          p={{ b: product ? "100%" : "75%" }}
          {...rest}
        />
      </Div>
    )
  }
}
